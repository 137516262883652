<template>
  <div>
    <!-- Search -->
    <b-card no-body>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            class="mb-md-0 mb-2"
          >
            <label>Clinic</label>
            <v-select
              v-model="sch_clinic"
              :options="clinicOptions"
              class="w-100"
              :reduce="name => name.code"
              label="name"
              placeholder="เลือกคลินิก"
              @input="changeRoute()"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            class="d-flex justify-content-end"
          >
            <b-form-group
              class="mt-2"
            >
              <b-form-radio-group
                v-model="sch_doctorType"
                button-variant="outline-primary"
                :options="doctorTypeOptions"
                buttons
                size="sm"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card
      no-body
      class="p-2"
    >
      <b-table
        responsive="sm"
        outlined
        hover
        :busy="isBusy"
        :items="doctorLists"
        :fields="['docCode', 'docName']"
        @row-clicked="showCalendar"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle" />
            <strong>Loading...</strong>
          </div>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      sch_clinic: '',
      sch_doctorType: '',
      clinicOptions: [],
      doctorTypeOptions: [
        { text: 'ทั้งหมด', value: 'All' },
        { text: 'ออกตรวจวันนี้', value: 'Today' },
      ],
      doctorLists: [],
      nursePage: true,
      OverlayPatientList: false,
      selectedRadio: '',
      isBusy: false,
    }
  },
  computed: { },
  created() {
    this.sch_doctorType = 'Today'
    this.getClinicByReg()

    if (this.$route.params.clinic && this.$route.params.clinic !== 'null') {
      this.sch_clinic = this.$route.params.clinic
      this.getDoctorByClinic()
    }
  },
  methods: {
    changeRoute() {
      this.$router.push(`/doctor/list/${this.sch_clinic}`).catch(() => {})
      // this.patientLists = []
      this.getDoctorByClinic()
    },
    showCalendar(record, index) {
      // console.log(record, index)
      this.$router.push(`/doctor/schedule/${record.clinicCode}/${record.doctorCodeID}`).catch(() => {})
    },
    getClinicByReg() {
      this.$http({
        url: '/api/ClinicDoctor/getClinic',
        method: 'GET',
        data: {},
      }).then(({ data }) => {
        const result = []
        for (let i = 0; i < data.length; i += 1) {
          const clinicname = `${data[i].clinicCode} - ${data[i].clinicName}`
          result.push({ id: i + 1, code: data[i].clinicCode, name: clinicname })
        }
        this.clinicOptions = result
      }).catch(error => {
        // console.log(error)
      })
    },
    getDoctorByClinic() {
      this.isBusy = true
      this.doctorLists = null
      this.$http({
        url: `/api/Doctor/${this.sch_clinic}`,
        method: 'GET',
        data: {},
      }).then(({ data }) => {
        this.doctorLists = data
        this.isBusy = false
      }).catch(error => {
        // console.log(error)
      })
    },
    getDoctorCode(clinic) {
      for (let index = 0; index < this.options_clinic_doctor.length; index += 1) {
        if (this.options_clinic_doctor[index].clinicCode === clinic) {
          return this.options_clinic_doctor[index].docCode
        }
      }
      return ''
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
